import {request} from '@/utils/request'

// 列表
export function getArticle(data) {
  return request('GET','/article', data, true, true)
}
// 详情
export function getArticleInfo(id) {
  return request('GET','/article/' + id, '', true, true)
}
// 合作
export function postCooperate(data) {
  return request('POST','/businessCooperation', data, true, true)
}



