<template>
    <div v-html="detail" style="padding: 32px"></div>
</template>

<script>
  import {getArticle, getArticleInfo} from "../../api/shop/article";
  export default {
    name: "pack",
    data () {
      return {
        detail: ''
      }
    },
    created() {
      this.getList()
    },
    methods: {
      getList () {
        getArticle({
          title: '隐私政策',
          type: '3',
          page: 1,
          pageSize: 10
        }).then(res => {
          if (res.code === 0) {
            if (res.data.records.length > 0) {
              this.getDetails(res.data.records[0].id)
            }
          }
        })
      },
      getDetails (id) {
        getArticleInfo(id).then(res => {
          if (res.code === 0) {
            this.detail = res.data.detail
          }
        })
      },
    }
  }
</script>
